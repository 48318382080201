.Languages {
  position: relative;

  //SUBMENU
  .submenu {
    position: absolute;
    right: -0.3rem;
    bottom: -0.5rem;
    transform: translateY(95%);
    background: white;
    padding: 15px 15px 0px 15px;
    border-radius: 5px;
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.24);
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.24);
    opacity: 0;
    pointer-events: none;
    transition: all ease-in-out 300ms;
  }

  .submenu_active {
    opacity: 1;
    pointer-events: initial;
    transform: translateY(100%);
  }
}
