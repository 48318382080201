.sidebar_container {
  --color_nav_txt: rgb(100, 116, 139);
  --color_nav_ctg: rgb(160, 152, 174);
  --color_nav_active: rgb(255, 255, 255);
  --color_nav_not_active: rgb(160, 152, 174);
  --color_nav_active_bg: rgb(76, 188, 154);
  --nav_width: 12rem;
  --nav_closed_width: 3.5rem;
  padding: var(--padding-md) var(--padding-sm);
  padding-top: 8px;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.02em;
  color: var(--color_nav_txt);

  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  z-index: 1;

  &:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }

  @media screen and (max-width: 769px) {
    transform: translateX(0);
    position: fixed;
    z-index: 10000;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
  }

  @media screen and (max-width: 18rem) {
    width: 100vw;
  }
}

.sidebar_container::-webkit-scrollbar {
  width: 6px;
}

.sidebar_container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.sidebar_container::-webkit-scrollbar-thumb {
  background-color: rgb(76, 188, 154);
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.sidebar_container::-webkit-scrollbar-thumb:hover {
  background-color: rgb(60, 174, 139);
}

.sidebar_container {
  scrollbar-width: thin;
  scrollbar-color: rgb(76, 188, 154) #f1f1f1;
}

nav {
  position: relative;
  width: var(--nav_width);
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  transition: width 300ms linear;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgLogo {
  max-width: 100%;
  height: auto;
}

.menu_container {
  margin-top: var(--margin-lg);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.li_navlink {
  margin-bottom: 0.1rem;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    text-decoration: none;
    color: var(--color_nav_ctg);
    border-radius: 8px;
    transition:
      background-color 0.3s,
      color 0.3s,
      transform 0.2s ease-in-out;
    width: 100%;

    &:hover {
      background-color: var(--color_nav_active_bg);
      color: var(--color_nav_active);
      transform: translateY(-2px);
    }

    .description {
      margin-left: 0.75rem;
      font-weight: 500;
      transition: opacity 200ms ease;
    }

    svg {
      font-size: 1.25rem;
      color: var(--color_nav_not_active);
      transition: color 0.3s ease-in-out;
    }

    &:hover svg {
      color: var(--color_nav_active);
    }
  }

  &.menuOpen {
    a {
      font-weight: 900;
      background-color: rgba(64, 224, 208, 0.5);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      transition: background-color 0.3s ease;
    }

    &:hover a {
      background-color: var(--color_nav_active_bg) !important;
      color: var(--color_nav_active);
      transform: translateY(-2px);
    }
  }

  .active {
    background-color: var(--color_nav_active_bg);
    color: var(--color_nav_active);
    transform: translateY(-2px);
    width: 100%;

    svg {
      color: var(--color_nav_active);
    }

    &:hover {
      background-color: var(--color_nav_active_bg) !important;
      color: var(--color_nav_active) !important;
      transform: translateY(-2px);
    }
  }
}

.minusItem {
  font-size: 0.9rem;
  margin-right: 0.5rem;
  width: 100%;

  &.menuClosed {
    svg {
      width: 0;
      height: 0;
      display: none;
    }
  }
}

.menuArrow {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  opacity: 0.6;
  transform: translateY(-50%);
  cursor: pointer;
  transition: transform 0.3s ease;

  svg {
    color: var(--color_nav_ctg);
    max-width: 80%;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.3);
    }
  }

  &:hover {
    transform: translateY(-50%) translateX(2px);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.nav_small {
  width: var(--nav_closed_width);
  transition: width 0.3s ease;
  @media screen and (max-width: 768px) {
    width: 0;
    display: none;
  }

  .description {
    opacity: 0;
  }

  .btn_logout svg {
    transform: scaleX(-1);
  }
}

ol,
ul {
  padding-left: 0 !important;
}

.customFont {
  font-size: 10px !important;
  line-height: 0.8 !important;
}

.customDescription {
  margin-left: 0.75rem;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 1 !important;
  opacity: 1;
  transition:
    margin-left 300ms ease,
    font-size 300ms ease,
    opacity 200ms ease;

  &.menuClosedcustomDescription {
    font-size: 10px !important;
    line-height: 1 !important;
    margin-left: -10px;
    opacity: 0.8;
  }

  &.menuOpencustomDescription {
    font-size: 13px !important;
    line-height: 1 !important;
    margin-left: 0.75rem;
    opacity: 1;
    transition:
      margin-left 300ms ease,
      font-size 300ms ease,
      opacity 200ms ease;
  }
}
