.avatar_container {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 10px;

  &:before {
    width: 1px;
    height: 1.5rem;
    background: rgb(223, 223, 223);
  }

  .menu {
    position: absolute;
    width: 220px;
    right: 1.5rem;
    bottom: -0.5rem;
    transform: translateY(95%);
    background: white;
    padding: 15px;
    border-radius: 5px;
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.24);
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.24);
    opacity: 0;
    pointer-events: none;
    transition: all ease-in-out 300ms;

    .info {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid gainsboro;

      .name {
        font-size: 1rem;
        font-weight: 400;
      }

      .role {
        font-weight: 400;
        font-size: 0.7rem;
        color: rgb(92, 92, 92);
      }
    }

    .settings {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 0.8rem;

      a {
        text-decoration: none;
        color: rgb(92, 92, 92);
        font-size: 0.7rem;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .menu_active {
    display: block;
    pointer-events: initial;
    opacity: 1;
    transform: translateY(100%);
  }
}

.icon_avatar_container {
  max-width: 2rem;
  max-height: 2rem;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}

.name {
  display: flex;
  align-items: center;
  gap: 0.1rem;
  cursor: pointer;

  span {
    font-size: 0.5rem;
    font-weight: 500;
    color: #475569;

    @media screen and (max-width: 360px) {
      display: none;
    }
  }

  svg {
    color: rgb(141, 140, 140);
    font-size: 1.3rem;
  }
}

.subMenuIcon {
  font-size: 12px !important;
  padding-right: 6px;
}

.subMenuText {
  font-size: 13px !important;
}
