@use '../../assets/global' as global;

@import '../../assets/Badge.module.scss';

$custom-colors: global.$custom-colors;

@include global.text-colors($custom-colors);
@include global.background-colors($custom-colors);

.noHover {
  &:hover {
    background-color: inherit;
    color: inherit;
  }
}
