.container {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-sm);
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  margin-top: 5px;

  @media screen and (width <= 768px) {
    margin-bottom: 80px;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    background: white;
    width: 100%;

    &.specialContainer {
      margin-top: 70px;
    }
  }
}

.burger_container {
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    margin-left: 5px;

    svg {
      font-size: 0.6rem;
    }
  }
  svg {
    font-size: 1.5rem;
  }
}

.recommed {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 7px;

  @media screen and (min-width: 769px) {
    margin-right: 20px;
  }

  @media screen and (max-width: 768px) {
    width: 40%;
  }

  @media screen and (max-width: 480px) {
    width: 20%;
  }

  @media screen and (max-width: 320px) {
    width: 10%;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
  margin-top: 7px;
  margin-right: 15px;
  padding: var(--padding-sm);
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    width: auto;
    margin: 0;
    gap: 1.5rem;
    justify-content: flex-end;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 8px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width: auto;
  }
}

.feedback {
  display: flex;
  justify-content: center;
  width: 30%;
  gap: 0.5rem;
  margin-left: 30%;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    width: 80%;
    margin-left: 10%;
  }
}

.languages,
.notifications,
.basket,
.myProfile {
  @extend .card;
}
