$danger: #f03;

.badgeCustom {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: $danger !important;

  &.small {
    font-size: 0.6rem;
    padding: 0.15em 0.3em;
  }

  &.medium {
    font-size: 0.75rem;
    padding: 0.25em 0.4em;
  }

  &.large {
    font-size: 1rem;
    padding: 0.4em 0.6em;
  }
}
