.iconContainerBasket {
  position: relative;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  width: auto;
  justify-content: center;
  transition: background 200ms ease;

  &:hover {
    background: rgb(202, 202, 202);
  }
}

$danger: #ff0033;

.badgeCustomBasket {
  display: inline-block;
  padding: 0.15em 0.3em;
  font-size: 65%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.2rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: $danger !important;

  &.small {
    font-size: 0.5rem;
    padding: 0.1em 0.2em;
  }

  &.medium {
    font-size: 0.45rem;
    padding: 0.08em 0.18em;
  }

  &.large {
    font-size: 0.7rem;
    padding: 0.35em 0.5em;
  }
}
