@use './Icons' as *;

.notifications {
  position: relative;

  .submenu {
    position: absolute;
    right: -1rem;
    width: 20vw;
    bottom: -0.5rem;
    transform: translateY(95%);
    background: white;
    padding: 15px;
    border-radius: 5px;
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.24);
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.24);
    opacity: 0;
    pointer-events: none;
    transition: all ease-in-out 300ms;

    svg {
      padding: 0px 3px 4px 3px !important;
    }

    @media screen and (max-width: 600px) {
      width: 90vw;
      right: -11.1rem;
    }
  }

  .submenu_active {
    opacity: 1;
    pointer-events: initial;
    transform: translateY(100%);
  }
}
