@use '../../assets/global' as global;

$custom-colors: global.$custom-colors;

@include global.text-colors($custom-colors);

$form-heading-color: #a098ae;

.titleBorder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $form-heading-color;
  border-bottom: 1px solid $form-heading-color !important;
  width: 100%;
  padding-bottom: 0.2rem;
}

.h7 {
  font-size: 0.75rem;
}

.h8 {
  font-size: 0.65rem;
}

.h9 {
  font-size: 0.5rem;
}
