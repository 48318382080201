$background-color_1: rgba(0, 0, 0, 0.25);

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background-color_1;
  animation-name: appear;
  animation-duration: 300ms;
}

.modal_dialog {
  width: 100%;
  //max-width: 550px;
  background: white;
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: slide-in;
  animation-duration: 0.5s;
}

.modal_header {
  display: flex;
  align-items: center;
  padding: 1rem;
  //border-bottom: 1px solid #dbdbdb;
  justify-content: space-between;
}

.modal_footer {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #dbdbdb;
  justify-content: flex-end;
}

.modal_close {
  cursor: pointer;
  margin-top: 10px;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 0.8rem;
  line-height: 0.7;
}

.modal_body {
  overflow: auto;
}

.modal_content {
  padding: 1rem;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }

  to {
    transform: translateY(0);
  }
}
