.overlayToast {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 9999 !important;
}

.toastBase {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 10001 !important;
  max-width: 90vw !important;

  @media (max-width: 767px) {
    position: fixed !important;
    top: calc(80% + 200px) !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: 90vw !important;
    z-index: 10001 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    position: fixed !important;
    top: calc(40% + 0px) !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: 90vw !important;
    z-index: 10001 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.smallToastStyle {
  @extend .toastBase;
  width: 300px !important;
}

.mediumToastStyle {
  @extend .toastBase;
  width: 400px !important;
}

.largeToastStyle {
  @extend .toastBase;
  width: 500px !important;
}

.xlargeToastStyle {
  @extend .toastBase;
  width: 600px !important;
}
