@import 'bootstrap';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

// @mixin font-poppins {
//   @font-face {
//     font-family: 'Poppins';
//     src: url('/fonts/poppins-v21-latin-500.eot');
//     src: url('/fonts/poppins-v21-latin-regular.eot?#iefix')
//         format('embedded-opentype'),
//       url('/fonts/poppins-v21-latin-regular.woff2') format('woff2'),
//       url('/fonts/poppins-v21-latin-regular.woff') format('woff'),
//       url('/fonts/poppins-v21-latin-regular.ttf') format('truetype'),
//       url('/fonts/poppins-v21-latin-regular.svg#Poppins')
//         format('svg');
//     font-weight: 400;
//     font-style: normal;
//   }

//   @font-face {
//     font-family: 'Poppins';
//     src: url('/fonts/poppins-v21-latin-500.eot');
//     src: url('/fonts/poppins-v21-latin-500.eot?#iefix')
//         format('embedded-opentype'),
//       url('/fonts/poppins-v21-latin-500.woff2') format('woff2'),
//       url('/fonts/poppins-v21-latin-500.woff') format('woff'),
//       url('/fonts/poppins-v21-latin-500.ttf') format('truetype'),
//       url('/fonts/poppins-v21-latin-500.svg#Poppins') format('svg');
//     font-weight: 500;
//     font-style: normal;
//   }
// }

// @include font-poppins;

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
  margin: 0;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  -webkit-text-size-adjust: 100%;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

$custom-colors: (
  'custom': #4cbc9a,
  't-gri': #a098ae,
  'lightcustom': #e8f3f0,
  't-mor': #6244ff,
  't-turuncu': #ff8f80,
  't-sarı': #ffdb80,
  't-mavi': #2196f3,
  't-calendar': #3a3650,
  'landing-green': #00c905,
  'online-green': #91dfc8,
  'd-online': #4cbc9a,
  'd-kids': #fc6b57,
  'd-cafe': #fec64f,
  'd-company': #0e2748,
  'c-gri': #e6e6e6,
  'c-orange': #f77320,
  't-dark-yellow': #cccc00,
  'custom-danger': #ff0033,
  'krem': #f5f5dc,
  'turuncu': #ffa500,
  'secondary-light': #6c757d,
  'checked': #3aa76d,
  'neutral': #f2f2f2,
  'comparingWhite': #f8f9fa,
  'landingGift': #00331b,
);

@mixin text-colors($custom-colors) {
  @each $color-name, $color-value in $custom-colors {
    .text-#{$color-name} {
      color: $color-value !important;
    }
  }
}

@mixin background-colors($custom-colors) {
  @each $color-name, $color-value in $custom-colors {
    .bg-#{$color-name} {
      background-color: $color-value;
      transition: background-color 0.3s ease;

      &:not(div):hover {
        background-color: lighten($color-value, 6%);
      }
    }

    .bg-#{$color-name}-opacity-25 {
      background-color: rgba($color-value, 0.25);
    }

    .bg-#{$color-name}-opacity-50 {
      background-color: rgba($color-value, 0.5);
    }

    .bg-#{$color-name}-opacity-75 {
      background-color: rgba($color-value, 0.75);
    }

    .bg-#{$color-name}-opacity-90 {
      background-color: rgba($color-value, 0.9);
    }
  }
}

@mixin outline-colors($custom-colors) {
  @each $color-name, $color-value in $custom-colors {
    .outline-#{$color-name} {
      color: $color-value;
      border: 1px solid lighten($color-value, 5%);
      background-color: transparent;
      transition:
        background-color 0.3s ease,
        color 0.3s ease,
        border-color 0.3s ease;

      &:hover {
        background-color: $color-value;
        color: #fff;
      }

      &:focus,
      &:active {
        box-shadow: 0 0 0 0.25rem rgba($color-value, 0.25);
      }
    }
  }
}

@include text-colors($custom-colors);
@include background-colors($custom-colors);
@include outline-colors($custom-colors);

$theme-colors: map-merge($theme-colors, $custom-colors);

// .borderLeft {
//   border-left: 1px solid #adb5bd;
// }

// @media only screen and (max-width: 768px) {
//   .borderLeft {
//     border-left: none;
//   }
// }

// .timelineBorderLeft {
//   border-left: 3px solid #adb5bd;
// }

// .current-time-indicator {
//   position: absolute;
//   background-color: hsl(217, 88.2%, 90%);
//   color: hsl(217, 88.8%, 35.1%);
//   border-radius: 50%;
//   height: 5px;
//   width: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .testPrice-group {
//   max-width: 100%;
//   overflow-x: scroll;
// }
